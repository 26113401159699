
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faQuestionCircle,
  faFileSignature,
  faLifeRing,
  faAdjust,
  faAnchor,
  faBalanceScale,
  faBed,
  faCircle,
  faCompass,
  faFileVideo,
  faHandshake,
  faShip,
  faPlusSquare,
  faPallet,
  faFileAlt,
  faSearchPlus,
  faLeaf,
} from '@fortawesome/free-solid-svg-icons';
import propeller from '@/../public/img/icons/propeller.svg';

import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
library.add(
  faQuestionCircle,
  faFileSignature,
  faLifeRing,
  faAdjust,
  faAnchor,
  faBalanceScale,
  faBed,
  faCircle,
  farCircle,
  faCompass,
  faFileVideo,
  faHandshake,
  faShip,
  faPlusSquare,
  faPallet,
  faFileAlt,
  faSearchPlus,
  faLeaf
);

export default Vue.extend({
  name: 'IconButton',

  props: {
    label: String,
    index: Number,
    iconsvg: {
      type: String,
      default: 'question-circle',
    },
    progress: Number,
    classname: String,
    icontype: {
      type: Boolean,
      default: true,
    },
    imageicon: {},
  },
  components: {
    FontAwesomeIcon,
  },
  methods: {
    getImage(img) {
      if (img === 'propeller') {
        return propeller;
      }

      return '';
    },
  },
});

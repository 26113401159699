
import Jimp from 'jimp/es';
import { hexUuid } from '@/Utils';
import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FilePreview from '@/components/FilePreview.vue';

export default defineComponent({
  props: {
    value: {},
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
    index: { type: String },
  },
  components: {
    FontAwesomeIcon,
    FilePreview,
  },
  methods: {
    dropFile(e) {
      this.$refs.files.files = e.dataTransfer.files;

      for (let im = 0; im < this.$refs.files.files.length; im += 1) {
        this.updateValue(this.$refs.files.files[im]);
      }

      this.$refs.files.value = '';
    },
    removeAnswerType(ind) {
      const jsonValue = this.fileList;

      jsonValue.splice(ind, 1);

      if (this.index) {
        this.value[this.index] = jsonValue;
        this.$emit('updateValue', [JSON.stringify(this.value), true]);
      } else {
        this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
      }
    },
    updateMultiple() {
      for (let im = 0; im < this.$refs.files.files.length; im += 1) {
        this.updateValue(this.$refs.files.files[im]);
      }

      this.$refs.files.value = '';
    },
    updateValue(fil) {
      (async () => {
        const fileUuid = hexUuid();
        const file = fil;
        if (!file) {
          return;
        }
        const info = {
          uuid: fileUuid,
          data: null,
          name: file.name,
          type: file.type,
          questionId: this.qa.question.uuid,
          reportId: this.report.uuid,
        };
        try {
          if (file.type.split('/')[1] === 'jpeg') {
            const J = Jimp as any;
            const buffer = await new Promise((res, rej) => {
              const fr = new FileReader();
              fr.onload = () => {
                res(fr.result);
              };
              fr.readAsArrayBuffer(file);
            });
            const image = (await J.read(buffer)) as any;
            const resizeBuff = await image
              .scaleToFit(1600, 1600, J.RESIZE_BICUBIC)
              .quality(60)
              .getBufferAsync('image/jpeg');
            info.data = new Blob([resizeBuff], { type: 'image/jpeg' });
            info.type = 'image/jpeg';
          } else if (file.type.split('/')[1] === 'pdf') {
            info.data = file;
          } else {
            alert('Invalid file');
            return;
          }

          this.$store.dispatch('answerAddImage', info);

          if (this.index) {
            const iList = this.fileList;
            iList.push(info.uuid);

            const count = this.$refs.files.attributes['data-count'].value;

            const jsonValue = this.value;

            jsonValue[count] = iList;

            this.$emit('updateValue', [JSON.stringify(jsonValue), true]);
          } else {
            const iList = this.fileList;
            iList.push(info.uuid);
            this.$emit('updateValue', [JSON.stringify(iList), true]);
          }
        } catch (error) {
          alert('Invalid file!');
        }
      })();
    },
    openInputFile() {
      this.$refs.files.click();
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    stringToArray(val) {
      if (this.isJsonString(val)) {
        return JSON.parse(val);
      }

      if (val === '') {
        return [];
      }
      return [val];
    },
  },
  computed: {
    fileList() {
      if (this.index) {
        if (this.value[this.index]) {
          return this.stringToArray(
            Array.isArray(this.value[this.index])
              ? JSON.stringify(this.value[this.index])
              : this.value[this.index]
          );
        }

        return [];
      }

      return this.stringToArray(this.value);
    },
  },
});

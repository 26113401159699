
import AppHeader from '@/components/AppHeader.vue';
import FormButton from '@/components/Forms/FormButton.vue';
import { defineComponent } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SyncStatus from '@/sync/SyncStatus';
import InputSelect from '@/components/Forms/InputSelect.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import LoadingOverlay from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { apolloClient } from '@/apolloClient';
import { cmdQueries } from '@/gql/CommandMutations';
import { reportRemove } from '@/sync/ReportDb';
import { removeFile } from '@/sync/FilesDb';
import { QuestionAnswer } from '@/datatypes/Question';
import { GqlAllMyAnswers } from '@/gql/AllMyReports';
import QNavBar from '@/components/QNavBar.vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import IaCard from '@/components/IaCard.vue';
import IaCardText from '@/components/IaCardText.vue';

library.add(faCheck);
library.add(faTimes);

export function sortQuestions(a, b) {
  const n = Number(a.inquestionsection) - Number(b.inquestionsection);
  if (n === 0) {
    return Number(a.inquestionnumber) - Number(b.inquestionnumber);
  } else {
    return n;
  }
}

export default defineComponent({
  name: 'FinaliseReport',
  mixins: [validationMixin],
  data() {
    return {
      masterSigned: '',
      syncStatus: SyncStatus.$data,
      inProgress: false,
    };
  },
  validations: {
    masterSigned: { required },
  },
  methods: {
    finalise() {
      if (!this.inspection.prop_inspectskipsignoff && this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.inProgress = true;
      apolloClient
        .mutate({
          mutation: cmdQueries.FinaliseInspectReport,
          variables: {
            reportId: this.report.uuid,
            masterSigned: this.masterSigned,
          },
        })
        .then(async () => {
          const allFiles = this.report.answers.reduce(
            (t, a) => t.concat(a.images),
            []
          );
          for (const file of allFiles) {
            await removeFile(file.uuid);
          }
          await reportRemove(this.report.uuid);
          this.$store.dispatch('clearReport');
          window.location.href = '/';
        })
        .catch((e) => {
          this.inProgress = false;
          console.error(e); // tslint:disable-line
          alert('Error Finalising report\n' + e);
        });
    },
  },
  mounted() {
    this.$store.dispatch('clearReport');
    this.$store.dispatch('selectReport', this.$route.params.reportId);
  },
  computed: {
    report() {
      return this.$store.state.report || {};
    },
    inspect() {
      return this.$store.state.inspect || {};
    },
    qa(): QuestionAnswerState[] {
      const qaGetter = this.$store.getters.qa as QuestionAnswerState[];
      return qaGetter.filter(
        (i) =>
          i.visible === true &&
          (i.valid.complete === false || i.valid.valid === false)
      );
    },
    questions() {
      return this.$store.state.inspection
        ? this.$store.state.inspection.questions
        : [];
    },
    validQuestion() {
      return this.questions.filter((x) => {
        if (x.inquestionrules === '' || x.inquestionrules === '{}') {
          return true;
        } else {
          const showList = JSON.parse(x.inquestionrules).show;
          if (showList) {
            return showList.every((m) => {
              let sourceValue = '';
              if (m.source === 'dwt') {
                sourceValue = this.dwt;
              } else if (m.source === 'winch') {
                sourceValue = this.winch;
              } else if (m.source === 'fuel') {
                sourceValue = this.fuel;
              } else if (m.source === 'hatch') {
                sourceValue = this.hatch;
              }

              if (m.source === 'fuel') {
                return m.values.includes(sourceValue);
              }

              return (
                (!m.low || sourceValue >= m.low) &&
                (!m.high || sourceValue <= m.high)
              );
            });
          }
        }
        return true;
      });
    },
    answers() {
      return this.$store.state.report ? this.$store.state.report.answers : [];
    },
    dwt() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesseldwt
        : 0;
    },
    fuel() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesselfuel
        : 0;
    },
    hatch() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesselhatch
        : 0;
    },
    winch() {
      return this.$store.state.report
        ? this.$store.state.report.inreportvesselwinches
        : 0;
    },
    badQuestions() {
      return this.qa.filter((i) => i.valid.valid !== true);
    },
    synchronised() {
      return 0 === this.syncStatus.cmdQueue + this.syncStatus.fileQueue;
    },
    canFinalise() {
      return (
        this.badQuestions.length === 0 &&
        this.incomplete.length === 0 &&
        this.synchronised &&
        this.missingVesselInfo.length === 0
      );
    },
    incomplete() {
      return this.qa.filter((i) => i.valid.complete !== true);
    },
    inspection() {
      return this.$store.state.inspection;
    },
    missingVesselInfo() {
      if (this.inspection.prop_inspectmode === 2) {
        return [];
      }
      const missingVessel = [];

      if (
        !this.report.inreportvesselclass ||
        this.report.inreportvesselclass === ''
      ) {
        missingVessel.push('Vessel Class');
      }

      if (
        !this.report.inreportvesseldwt ||
        this.report.inreportvesseldwt === ''
      ) {
        missingVessel.push('Vessel DWT');
      }

      if (
        !this.report.inreportvesselyear ||
        this.report.inreportvesselyear === ''
      ) {
        missingVessel.push('Vessel Year');
      }

      if (
        !this.report.inreportvesseltype ||
        this.report.inreportvesseltype === ''
      ) {
        missingVessel.push('Vessel Type');
      }

      return missingVessel;
    },
  },
  components: {
    IaCardText,
    IaCard,
    QNavBar,
    AppHeader,
    FormButton,
    InputSelect,
    FontAwesomeIcon,
    LoadingOverlay,
  },
});

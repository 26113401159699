export interface CrewExperienceValue {
  master?: RoleExperienceValue;
  chiefOfficer?: RoleExperienceValue;
  chiefEngineer?: RoleExperienceValue;
  secondEngineer?: RoleExperienceValue;
}

export type TimePeriod = `P${number}Y${number}M`;

export interface RoleExperienceValue {
  time: TimePeriod;
  previous: RolePrevExpValue[];
}
export interface RolePrevExpValue {
  rank: string;
  size: 'Handymax' | 'Panamax' | 'Capesize';
  time: TimePeriod;
}

export interface TimeValue {
  Y: number;
  M: number;
}

export function parseTime(s: TimePeriod | undefined | ''): TimeValue {
  if (s === '' || s === undefined) {
    return { Y: 0, M: 0 };
  }
  const m = /P([0-9]+)Y([0-9]+)M/.exec(s);
  if (m) {
    return { Y: parseInt(m[1], 10), M: parseInt(m[2], 10) };
  }
  return { Y: 0, M: 0 };
}

export function timeMonths(t: TimeValue): number {
  return t.Y * 12 + t.M;
}


import { defineComponent, PropType } from 'vue';
import { QuestionAnswerState } from '@/vuex/AnswerStore';
import { GqlAllMyReports } from '@/gql/AllMyReports';

import InspectMooringLine from '@/components/Forms/QuestionType/MooringLine.vue';
import InspectMooringLineLite from '@/components/Forms/QuestionType/MooringLineLite.vue';
import InspectNumber from '@/components/Forms/QuestionType/Number.vue';
import InspectDate from '@/components/Forms/QuestionType/Date.vue';
import InspectDropdown from '@/components/Forms/QuestionType/Dropdown.vue';
import InspectCountry from '@/components/Forms/QuestionType/Country.vue';
import InspectText from '@/components/Forms/QuestionType/Text.vue';
import InspectPhoneNumber from '@/components/Forms/QuestionType/PhoneNumber.vue';
import InspectEmailAddress from '@/components/Forms/QuestionType/EmailAddress.vue';
import InspectImo from '@/components/Forms/QuestionType/Imo.vue';
import InspectFile from '@/components/Forms/QuestionType/File.vue';
import InspectYesNo from '@/components/Forms/QuestionType/YesNo.vue';
import InspectTableYesNo from '@/components/Forms/QuestionType/TableYesNo.vue';
import InspectTableNumber from '@/components/Forms/QuestionType/TableNumber.vue';
import InspectTableDropdown from '@/components/Forms/QuestionType/TableDropdown.vue';
import InspectTableCountry from '@/components/Forms/QuestionType/TableCountry.vue';
import InspectTableFile from '@/components/Forms/QuestionType/TableFile.vue';
import InspectHatch from '@/components/Forms/QuestionType/Hatch.vue';
import InspectCrewExperience from './QuestionType/CrewExperience.vue';

export default defineComponent({
  setup() {
    return {
      comp: {
        'inspect-yes-no': 1,
        'inspect-number': 0x10,
        'inspect-date': 0x11,
        'inspect-dropdown': 0x18,
        'inspect-country': 0x19,

        'inspect-text': 0x20,
        'inspect-phone-number': 0x21,
        'inspect-email-address': 0x22,
        'inspect-imo': 0x23,
        MultilineText: 0x24,

        'inspect-file': 0x31,
        'inspect-hatch': 0x32,
        'inspect-mooring-line': 0x33,
        'inspect-crew-experience': 0x34,
        'inspect-mooring-line-lite': 0x35,

        'inspect-table-yes-no': 0x81, // 0x80 + 0x01
        'inspect-table-number': 0x90, // 0x80 + 0x10
        'inspect-table-dropdown': 0x98, // 0x80 + 0x18
        'inspect-table-country': 0x99, // 0x80 + 0x19
        'inspect-table-file': 0xb1, // 0x80 + 0x31
      },
    };
  },
  props: {
    value: { type: String },
    qa: { type: Object as PropType<QuestionAnswerState> },
    report: { type: Object as PropType<GqlAllMyReports> },
  },
  components: {
    InspectMooringLine,
    InspectMooringLineLite,
    InspectNumber,
    InspectDate,
    InspectDropdown,
    InspectCountry,
    InspectCrewExperience,
    InspectText,
    InspectPhoneNumber,
    InspectEmailAddress,
    InspectImo,
    InspectFile,
    InspectYesNo,
    InspectTableYesNo,
    InspectTableNumber,
    InspectTableDropdown,
    InspectTableCountry,
    InspectTableFile,
    InspectHatch,
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    updateValue(e) {
      this.$emit('input', [e[0], e[1]]);

      const toUpdate = this.qa.rules.field;

      if (toUpdate === 'inreportvesselfuel') {
        this.$store.dispatch('setVesselFuel', e[0]);
      }

      if (toUpdate === 'inreportvesseldwt') {
        this.$store.dispatch('setVesselDwt', e[0]);
      }

      if (toUpdate === 'inreportvesselwinches') {
        this.$store.dispatch('setVesselWinches', e[0]);
      }

      if (toUpdate === 'inreportvesselhatch') {
        this.$store.dispatch('setVesselHatch', e[0]);
      }
    },
  },
});

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.inProgress}}),_c('app-header'),_c('q-nav-bar',{on:{"back":function($event){return _vm.$router.push(`/report/${_vm.report.uuid}/section`)}}}),_c('div',{staticClass:"cap-width"},[_c('ia-card',[_c('ia-card-text',[_c('div',{staticClass:"statement ia-head"},[(_vm.badQuestions.length > 0)?_c('p',[(_vm.badQuestions.length === 0)?_c('font-awesome-icon',{staticClass:"check green",attrs:{"icon":"check"}}):_vm._e(),_vm._v(" All questions have valid answers. ")],1):_vm._e()]),(_vm.badQuestions.length > 0)?_c('div',{staticClass:"statement"},[_c('p',[_vm._v("Please complete the following questions:")]),_vm._l((_vm.badQuestions.slice(0, 10)),function(q,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(q.element)+"."+_vm._s(q.number)+" "+_vm._s(q.question.title)+" "),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push(
                  '/report/' + _vm.report.uuid + '/question/' + q.question.uuid
                )}}},[_vm._v(" Go To ")])])}),(_vm.badQuestions.length > 10)?_c('p',[_c('strong',[_vm._v(" + "+_vm._s(_vm.badQuestions.length - 10)+" more un-answered...")])]):_vm._e()],2):_vm._e(),(_vm.missingVesselInfo.length > 0)?_c('div',{staticClass:"statement"},[_c('p',[_c('font-awesome-icon',{staticClass:"check red",attrs:{"icon":"times"}}),_vm._v(" Please complete the following vessel information: ")],1),_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.missingVesselInfo.join(', '))+" ")]),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/report/' + _vm.report.uuid)}}},[_vm._v(" Go To Vessel Information ")])])]):_vm._e(),_c('div',{staticClass:"statement ia-head"},[(_vm.incomplete.length)?_c('p',[_c('font-awesome-icon',{staticClass:"check red",attrs:{"icon":"times"}}),_vm._v(" All questions have been answered and completed. ")],1):_vm._e()]),(_vm.incomplete.length > 0)?_c('div',{staticClass:"statement"},[_c('p',[_vm._v("Please answer the following questions:")]),_vm._l((_vm.incomplete.slice(0, 10)),function(q,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(q.element)+"."+_vm._s(q.number)+" "+_vm._s(q.question.title)+" "),_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push(
                  '/report/' + _vm.report.uuid + '/question/' + q.question.uuid
                )}}},[_vm._v(" Go To ")])])}),(_vm.incomplete.length > 10)?_c('p',[_c('strong',[_vm._v(" + "+_vm._s(_vm.incomplete.length - 10)+" more incomplete questions...")])]):_vm._e()],2):_vm._e(),_c('div',[_c('div',{staticClass:"statement ia-head"},[(!_vm.synchronised)?_c('p',[_c('font-awesome-icon',{staticClass:"check red",attrs:{"icon":"times"}}),_vm._v(" All data has been synchronised. ")],1):_vm._e()]),(!_vm.synchronised)?_c('div',{staticClass:"statement"},[_c('p',[_vm._v("You must reconnect, and allow data to synchronise")])]):_vm._e(),(_vm.canFinalise)?_c('div',{staticClass:"statement ia-head"},[_c('p',[_c('font-awesome-icon',{staticClass:"check green",attrs:{"icon":"check"}}),_vm._v(" All questions have been completed. ")],1)]):_vm._e(),(_vm.canFinalise)?_c('div',{staticClass:"form"},[_c('div',{staticClass:"button-wrap"},[_c('p',[_vm._v(" "+_vm._s(_vm.inspection.prop_inspectmode === 2 ? 'Does the vessel acknowledge all information in the Terminal Vetting Questionnaire is true and correct as the vessel may be audited whilst alongside for compliance.' : 'Master has signed off on observations?')+" ")]),_c('div',{staticClass:"wrap-yes-no"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.masterSigned.$model),expression:"$v.masterSigned.$model"}],attrs:{"type":"radio","value":"yes"},domProps:{"checked":_vm._q(_vm.$v.masterSigned.$model,"yes")},on:{"change":function($event){return _vm.$set(_vm.$v.masterSigned, "$model", "yes")}}}),_vm._v(" Yes ")]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.masterSigned.$model),expression:"$v.masterSigned.$model"}],attrs:{"type":"radio","value":"no"},domProps:{"checked":_vm._q(_vm.$v.masterSigned.$model,"no")},on:{"change":function($event){return _vm.$set(_vm.$v.masterSigned, "$model", "no")}}}),_vm._v(" No ")])]),_c('form-button',{class:{
                  'skip-signoff': _vm.inspection.prop_inspectskipsignoff,
                },attrs:{"label":(_vm.inspection.prop_inspectmode === 2
                    ? 'Submit'
                    : 'Finalise') + ' Report'},on:{"click":_vm.finalise}}),_c('br')],1),(_vm.inspection.prop_inspectmode !== 2)?_c('p',{staticStyle:{"max-width":"480px","margin":"10px auto"}},[_c('strong',[_vm._v("Please note:")]),_vm._v(" Finalising your inspection report is final, and may automatically notify the client and doc company that the report is complete and ready for viewing."),_c('br'),_c('br')]):_vm._e()]):_vm._e(),(!_vm.canFinalise)?_c('div',{staticClass:"button-wrap"},[_c('br'),(_vm.incomplete.length)?_c('form-button',{attrs:{"label":"Back to Report"},on:{"click":function($event){return _vm.$router.push(`/report/${_vm.report.uuid}/section`)}}}):_vm._e(),_c('br')],1):_vm._e()])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import Vue from 'vue';
import gql from 'graphql-tag';
import AppHeader from '@/components/AppHeader.vue';
import IconButton from '@/components/IconButton.vue';
import { reportList, reportRemove } from '@/sync/ReportDb';
import ReportItem from '@/components/ReportItem.vue';
import { apolloClient } from '@/apolloClient';
import { viewPdfReport } from '@/openReportUrl';
import LoadingOverlay from 'vue-loading-overlay';
import CredStore from '@/CredStore';

export default Vue.extend({
  name: 'home',
  data() {
    return {
      list: {
        reports: [],
        review: [],
      },
      result: {},
      wait: false,
    };
  },
  components: {
    AppHeader,
    IconButton,
    ReportItem,
    LoadingOverlay,
  },
  mounted() {
    this.$store.dispatch('clearReport');
    this.loadReportList();
  },
  methods: {
    edit(report) {
      this.$router.push('/report/' + report.uuid + '/section');
    },
    view(report) {
      viewPdfReport(report, 'std');
    },
    reject(report) {
      this.$router.push(`/report/${report.uuid}/reject`);
    },
    async accept(report) {
      if (confirm('Please confirm your review of this report')) {
        this.wait = true;
        apolloClient
          .mutate({
            mutation: gql`
              mutation approve($reportId: Uuid!) {
                FinaliseInspectReportReview(reportId: $reportId)
              }
            `,
            variables: { reportId: report.uuid },
          })
          .then(async () => {
            this.wait = false;
            await reportRemove(report.uuid);
            this.loadReportList();
          })
          .catch((e) => {
            this.wait = false;
            alert(`Error Approving Report ${e}`);
          });
      }
    },
    loadReportList() {
      reportList().then((list) => {
        this.list = {
          ...this.list,
          reports: list.sort((a, b) => a.title.localeCompare(b.title)),
        };
      });
    },
  },
  computed: {
    myReports() {
      return this.list.reports.filter(
        (x) => parseInt(x.inreportstatus, 10) <= 2
      );
    },
    awaitingReview() {
      return this.list.reports.filter(
        (x) => parseInt(x.inreportstatus, 10) === 4 && x.toreview !== true
      );
    },
    awaitingReviewForReviewer() {
      return this.list.reports.filter(
        (x) => parseInt(x.inreportstatus, 10) === 4 && x.toreview === true
      );
    },
    canCreate() {
      return CredStore.creator >= 8;
    },
    canSearch() {
      return CredStore.creator >= 4;
    },
  },
});
